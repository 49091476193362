<template>
  <div>
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Qualités
      </v-card-title>

      <v-divider class="mt-0"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.quality.field.keyword"
          placeholder="Rechercher par titre ou mot clé"
          label="Rechercher par titre ou mot clé"
          outlined
          hide-details
          dense
          clearable
          @click:clear="refreshQualities"
          class="user-search me-3 mb-4"
          @keyup="filterQualities"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-tooltip
            left
            color="primary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-4 me-3"
                small
                v-bind="attrs"
                @click.stop="openForm()"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Nouvelle qualité</span>
          </v-tooltip>

          <v-tooltip
            top
            color="secondary"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                class="mb-4 me-3"
                v-bind="attrs"
                small
                v-on="on"
                @click.prevent="refreshQualities"
              >
                <v-icon>{{ icons.mdiRefresh }}</v-icon>
              </v-btn>
            </template>
            <span>Raffraichir</span>
          </v-tooltip>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        loading-text="Chargement..."
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat"
        :headers="headers"
        :items="$store.getters['quality/qualities']"
        :items-per-page="$store.getters['quality/per_page']"
        :page.sync="$store.getters['quality/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="loading"
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formatingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formatingDate(`${item.updated_at}`) }}
        </template>
        <template #[`item.deleted_at`]="{item}">
          <v-chip
            v-show="item.deleted_at !== null && item.deleted_at !== undefined"
            small
            color="error"
          >
            {{ item.deleted_at !== null ? formatingDate(`${item.deleted_at}`) : null }}
          </v-chip>
        </template>

        <template #[`item.with_push_notif`]="{item}">
          <v-chip
            small
            :color="item.with_push_notif === true ? 'success' : 'error'"
          >
            {{ item.with_push_notif === true ? "OUI" : "NON" }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="openForm(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Modifier</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click.stop="showContentUrl(item.path)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>Voir</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ item.deleted_at !== null && item.deleted_at !== undefined? icons.mdiRestore : icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ item.deleted_at !== null && item.deleted_at !== undefined? "Restaurer" : "Supprimer" }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmDeleteItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteForever }}
                  </v-icon>
                  <span>Supprimer définitivement</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="$store.state.quality.field.paginate"
              :items="itemsPerPage"
              item-text="text"
              item-value="value"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getQualitiesPerPage"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getQualities"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <quality-form
      :dialog="dialog"
      :object="item"
      @clickout="dialog = false"
      @submitted="loading"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
    <link-content-dialog
      :dialog-content="showDialogContent"
      :url="contentUrl"
      @clickout="showDialogContent = false"
    />
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import { notify } from '@core/utils/notification'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical, mdiExportVariant,
  mdiFileDocumentOutline, mdiPencilOutline, mdiPlus, mdiRefresh, mdiRestore,
  mdiSquareEditOutline, mdiEye, mdiDeleteForever,
} from '@mdi/js'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import QualityForm from '@core/components/quality/QualityForm'
import LinkContentDialog from '@core/components/dialogs/LinkContentDialog'
import store from '@/store'

export default {
  name: 'Quality',
  components: { LinkContentDialog, QualityForm, ConfirmDialog },
  setup() {
    const loading = ref(false)
    const dialogDelete = ref(false)
    const message = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const error = ref(null)
    const isAddNewVideoSidebarActive = ref(false)
    const showDialogContent = ref(false)
    const forcedelete = ref(false)
    const messageDialog = ref('')
    const itemsPerPage = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ]
    const dialog = ref(false)
    const item = ref({})

    const openForm = val => {
      if (val) item.value = val
      else {
        item.value = {
          id: null,
          title: null,
          path: null,
          keywords: null,
          user_id: null,
          with_push_notif: null,
        }
      }
      dialog.value = true
    }

    const getQualities = (page = 1) => {
      store.state.quality.field.user = null
      store.state.quality.field.user_id = null
      loading.value = true
      store.dispatch('quality/getQualities', { page, field: store.state.quality.field }).then(() => {
        currentPage.value = store.getters['quality/current_page']
        lastPage.value = store.getters['quality/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const refreshQualities = (page = 1) => {
      loading.value = true
      store.state.quality.field.keyword = null
      store.state.quality.field.title = null
      store.state.quality.field.user = null
      store.state.quality.field.user_id = null
      store.dispatch('quality/refreshQualities', { page, field: store.state.quality.field }).then(() => {
        currentPage.value = store.getters['quality/current_page']
        lastPage.value = store.getters['quality/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    // eslint-disable-next-line camelcase
    const getQualitiesPerPage = per_page => {
      loading.value = true
      store.state.quality.field.paginate = per_page
      store.dispatch('quality/getQualities', { page: store.getters['quality/current_page'], field: store.state.quality.field }).then(() => {
        currentPage.value = store.getters['quality/current_page']
        lastPage.value = store.getters['quality/last_page']
        loading.value = false
        // eslint-disable-next-line no-shadow
      }).catch(errors => {
        message.value = errors.response.data.message
        error.value = errors.response.data.message
        loading.value = false
      })
    }

    const filterQualities = () => {
      setTimeout(() => {
        getQualities(store.getters['quality/current_page'])
      }, 1000)
    }

    const formatingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const confirmItem = object => {
      messageDialog.value = object.deleted_at === null || item.value.deleted_at === undefined? `Voulez-vous supprimer la qualité ${object.title}?` : `Voulez-vous restaurer la qualité ${object.title}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = false
    }

    const confirmDeleteItem = object => {
      messageDialog.value = `Voulez-vous supprimer définitivement la qualité ${object.title}?`
      item.value = object
      dialogDelete.value = true
      forcedelete.value = true
    }

    const deleteItem = () => {
      if (forcedelete.value === false) {
        if (item.value.deleted_at === null || item.value.deleted_at === undefined) {
          store.dispatch('quality/deleteQuality', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        } else {
          store.dispatch('quality/restoreQuality', item.value).then(response => {
            dialogDelete.value = false
            notify(true, true, response.message)
          }).catch(err => {
            message.value = err.response.data.message
            error.value = err.response.data.message
            dialogDelete.value = false
            notify(true, true, err.response.data.message)
          })
        }
      } else {
        store.dispatch('quality/forceDeleteQuality', item.value).then(response => {
          dialogDelete.value = false
          notify(true, true, response.message)
        }).catch(err => {
          message.value = err.response.data.message
          error.value = err.response.data.message
          dialogDelete.value = false
          notify(true, true, err.response.data.message)
        })
      }
    }

    onMounted(() => {
      getQualities(store.getters['quality/current_page'])
    })

    const contentUrl = ref(null)

    const showContentUrl = val => {
      contentUrl.value = val
      showDialogContent.value = true
    }

    return {
      showDialogContent,
      showContentUrl,
      contentUrl,
      forcedelete,
      deleteItem,
      confirmItem,
      confirmDeleteItem,
      messageDialog,
      dialogDelete,
      dialog,
      openForm,
      item,
      refreshQualities,
      getQualities,
      formatingDate,
      getQualitiesPerPage,
      filterQualities,
      isAddNewVideoSidebarActive,
      loading,
      error,
      currentPage,
      lastPage,
      message,
      itemsPerPage,
      headers: [
        { text: 'TITRE', value: 'title', sortable: false },
        { text: 'MOTS CLES', value: 'keywords', sortable: false },
        { text: 'AVEC NOTIFICATION PUSH', value: 'with_push_notif', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiRefresh,
        mdiPencilOutline,
        mdiRestore,
        mdiEye,
        mdiDeleteForever,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
