<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier une qualité" : "Nouvelle qualité" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="item.title"
                    type="text"
                    label="Titre"
                    clearable
                    :rules="[rules.title]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-combobox
                    v-model="chips"
                    chips
                    clearable
                    label="Mot clés"
                    multiple
                    variant="solo"
                    :rules="[rules.keywords]"
                    :loading="keywordLoader"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :model-value="selected"
                        closable
                        @click="select"
                        @click:close="remove(item)"
                      >
                        <strong>{{ item }}</strong>&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-file-input
                    v-model="file"
                    accept=".pdf"
                    show-size
                    counter
                    label="Fichier pdf"
                    :rules="rules.path"
                    :show-size="false"
                    :counter="false"
                    :loading="isDialogVisible"
                    @change="upload"
                  >
                  </v-file-input>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="item.with_push_notif"
                    :items="with_push_notifs"
                    item-text="text"
                    item-value="value"
                    label="Avec notification push"
                    type="text"
                    :rules="[rules.with_push_notif]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, watch,
} from '@vue/composition-api'
import { notify } from '@core/utils/notification'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import store from '@/store'

export default {
  name: 'QualityForm',

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const date = ref(null)
    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const keywordLoader = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const file = ref(null)

    const rules = {
      title: true,
      path: [true],
      with_push_notif: true,
      keywords: true,
    }
    const chips = ref([])

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const close = () => {
      message.value = ''
      isAlertVisible.value = false
      rules.path = [true]
      rules.title = true
      rules.with_push_notif = true
      rules.keywords = true
      instance.emit('clickout')
    }

    watch(() => props.dialog, dialog => {
      // eslint-disable-next-line no-unused-expressions
      dialog || close()
    })

    const upload = event => {
      const formData = new FormData()
      if (event) {
        isDialogVisible.value = true
        isAlertVisible.value = false
        formData.append('document', event)
        store.dispatch('quality/uploadFile', { model: 'quality', field: formData }).then(response => {
          item.value.path = store.state.quality.file
          notify(true, true, response.message)
          isDialogVisible.value = false
        }).catch(error => {
          message.value = error.response.data.message
          isAlertVisible.value = true
          rules.path = error.response.data.errors.document !== undefined ? error.response.data.errors.document : [true]
          notify(false, true, error.response.data.message)
          isDialogVisible.value = false
        })
      }
    }

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      item.value.keywords = chips.value
      if (item.value.id !== null) {
        store.dispatch('quality/updateQuality', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.title = error.response.data.errors.title !== undefined ? error.response.data.errors.title[0] : true
            rules.path = error.response.data.errors.path !== undefined ? error.response.data.errors.path : [true]
            rules.with_push_notif = error.response.data.errors.with_push_notif !== undefined ? error.response.data.errors.with_push_notif[0] : true
            rules.keywords = error.response.data.errors.keywords !== undefined ? error.response.data.errors.keywords[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('quality/addQuality', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.title = error.response.data.errors.title !== undefined ? error.response.data.errors.title[0] : true
            rules.path = error.response.data.errors.path !== undefined ? error.response.data.errors.path : [true]
            rules.with_push_notif = error.response.data.errors.with_push_notif !== undefined ? error.response.data.errors.with_push_notif[0] : true
            rules.keywords = error.response.data.errors.keywords !== undefined ? error.response.data.errors.keywords[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }
    const remove = item => {
      chips.value.splice(this.chips.indexOf(item), 1)
    }

    watch(() => props.object, value => {
      file.value = null
      chips.value = []
      if (value.id !== null) {
        keywordLoader.value = true
        store.state.contentKeyword.field.quality_id = value.id
        store.dispatch('contentKeyword/getContentKeywords', {
          page: 1,
          field: store.state.contentKeyword.field,
        }).then(() => {
          store.getters['contentKeyword/contentKeywords'].forEach(keyword => {
            chips.value.push(keyword.value)
          })
          keywordLoader.value = false
        }).catch(error => {
          notify(false, true, error.response.data.message)
          keywordLoader.value = false
        })
      }
    })

    return {
      keywordLoader,
      remove,
      chips,
      items: ['Streaming', 'Eating'],
      upload,
      close,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      item,
      color,
      file,
      with_push_notifs: [
        { text: 'OUI', value: true },
        { text: 'NON', value: false },
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

}
</script>

<style scoped>

</style>
